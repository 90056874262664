export type ShuttleData = {
	language: string;
	name: string;
	schedule: string;
};

export type Shuttle = {
	id: number;
	name: string;
	schedule: string;
	nameEn: string;
	scheduleEn: string;
	enabled?: boolean;
	edited?: boolean;
	new?: boolean;
	default?: string;
	translations?: ShuttleData[];
};

export type RestaurantData = {
	language: string;
	name: string;
	details: string;
};

export type Restaurant = {
	id: number;
	enabled: boolean;
	edited?: boolean;
	new?: boolean;
	translations: RestaurantData[];
};

export type MenuSection = {
	id: number;
	order: number;
	ungroupedSection?: boolean;
	translations: {
		language: string;
		text: string;
	}[];
	items: MenuItem[];
	created?: boolean;
	modified?: boolean;
	deleted?: boolean;
};

export type MenuItem = {
	id: number;
	order: number;
	section: {
		language: string;
		text: string;
	}[];
	permission: string;
	endIcon: string;
	active: boolean;
	startIcon: string;
	created?: boolean;
	modified?: boolean;
	deleted?: boolean;

	translations: MenuItemTranslation[];
};

export type MenuItemTranslation = {
	language: string;
	text: string;
	externalLink?: {
		active: boolean;
		inAppBrowser: boolean;
		url: string;
	};
	externalAppLink?: {
		active: boolean;
		deepLinkAndroid: string;
		deepLinkIOS: string;
		downloadAppAndroid: string;
		downloadAppIOS: string;
		webUrl: string;
	};

	file?: {
		active: boolean;
		fileName: string;
		url: string;
		file?: any;
	};

	internalLink?: {
		active: boolean;
		url: string;
	};

	customContent?: {};
};

export type MenuConfig = MenuSection[];

export enum ConfigType {
	EXTERNAL_LINK = 'externalLink',
	FILE = 'file',
	EXTERNAL_APP_LINK = 'externalAppLink',
	INTERNAL_LINK = 'internalLink',
}

export type IIcon = {
	id: number;
	name: string;
	url: string;
	svgContent?: string;
	svgName?: string;
};
