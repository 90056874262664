import { IonContent, IonFooter, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { IIcon } from '../../../../_api/services/generalServices/types';
import styles from './styles.module.scss';
import { TextInput } from '../../../../components/TextInput/TextInput';
import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { useState } from 'react';
import _ from 'lodash';

type Props = {
	showModal: boolean;
	selectedIcon: IIcon;
	onClose: () => void;
	saveChanges: (state: IIcon) => void;
	setSuccess?: any;
};

export const EditIconModal: React.FC<Props> = ({ selectedIcon, showModal, onClose, saveChanges, setSuccess }) => {
	const { t } = useTranslation();
	const [icon, setIcon] = useState(selectedIcon);
	const [canSave, setCanSave] = useState(false);

	const handleNameChange = (e) => {
		const newName = e.target.value;
		setCanSave(!_.isEqual(newName, selectedIcon.name));
		setIcon((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				name: newName,
			};
		});
	};

	const handleSave = () => {
		saveChanges(icon);
		setSuccess(t('toast_icon_name_changed'));
		onClose();
	};
	return (
		<Modal
			isOpen={showModal}
			onDidDismiss={() => {
				onClose();
			}}
			className={styles.modal}
		>
			<IonToolbar>
				<Button slot="end" fill={'clear'} color="light" className="btnCloseModal" onClick={onClose}>
					<IonLabel className="dark">
						<Icon color="primary" className="icon icon-close icon20"></Icon>
					</IonLabel>
				</Button>
				<IonTitle>
					<h3 className={`ion-text-center ${styles.headline}`}>
						<b>{`${t('edit_name')}`}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<p className={styles.footnote}>{t('icon_modal_name_change')}</p>

				<TextInput
					name="iconName"
					className="ion-text-end"
					onIonInput={handleNameChange}
					value={icon.name}
					maxlength={32}
					placeholder={t('icon_name')}
					aria-label={t('icon_name')}
					label={t('icon_name')}
				></TextInput>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						className={`${styles.btnHeader} ${styles.secondaryBtn}`}
						onClick={() => {
							onClose();
						}}
					>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						disabled={!canSave || icon.name.trim() === ''}
						onClick={handleSave}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};
