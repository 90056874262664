import { IonImg } from '@ionic/react';
import styles from './styles.module.scss';

type Props = {
	url: string;
	ariaLabel: string;
};
export const ServiceIcon: React.FC<Props> = ({ url, ariaLabel }) => {
	return <IonImg className={styles.iconImg} src={url} aria-label={ariaLabel} />;
};
