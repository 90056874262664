import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@acciona/ui-ionic-kit';
import { IonButton, IonContent, IonItem, IonLabel, IonList, IonLoading, IonPopover } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import LayoutNoTabs from '../../components/LayoutNoTabs/LayoutNoTabs';
import { generalServicesService } from '../../_api/services/generalServices';
import { IIcon } from '../../_api/services/generalServices/types';
import { usePermissions } from '../../hooks/usePermissions';
import { FUNCTIONALITIES } from '../../utils/constants';
import styles from './styles.module.scss';
import useAppContext from '../../hooks/useAppContext';
import _ from 'lodash';
import { ServiceIcon } from './components/ServiceIcon/ServiceIcon';
import { EditIconModal } from './components/EditIconModal/EditIconModal';
import { Feedback } from '../../components/Feedback/Feedback';
import { popoverOptions } from './helpers';
import { AddIconModal } from './components/AddIconModal/AddIconModal';

export const IconsTransversal: React.FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [icons, setIcons] = useState<IIcon[]>([]);
	const [selectedIcon, setSelectedIcon] = useState<IIcon>();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [isEdited, setIsEdited] = useState(false);
	const { permissions: userHasPermissions } = usePermissions(FUNCTIONALITIES.superadmin, 'WRITE');
	const [showEditIconModal, setShowEditIconModal] = useState(false);
	const [showAddIconModal, setShowAddIconModal] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [info, setInfo] = useState(null);
	const handleDismissToast = (type: 'error' | 'success' | 'info') => {
		if (type === 'error') {
			setError('');
		}
		if (type === 'success') {
			setSuccess('');
		}
		if (type === 'info') {
			setInfo(null);
		}
	};

	const { data: initialValues, isLoading: isLoading } = useQuery(
		['getIcons'],
		generalServicesService.getTransversalIcons,
		{
			onSuccess: (data: any) => {
				setIcons(data);
			},
			refetchOnWindowFocus: false,
			onError: (error: Error) => {
				setError(error.message);
			},
		},
	);

	const { mutate, isLoading: loadingSave } = useMutation(
		async (icons: IIcon[]) => await generalServicesService.updateTranversalIconsName(icons),
		{
			onSuccess: () => {
				queryClient.refetchQueries('getIcons');
				setSuccess(t('msg_success'));
			},
			onError: (err) => {
				setError(err);
			},
		},
	);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	useEffect(() => {
		setIsEdited(!_.isEqual(icons, initialValues));
	}, [icons]);

	const handleOptions = (icon: IIcon, orderId: number) => {
		setSelectedIcon(icon);
		if (orderId === 2) {
			//TODO handleDelete
			console.log('Borrar icono ', icon.id);
			return;
		}
		setShowEditIconModal(true);
	};

	const handleUpdateIconName = (icon: IIcon) => {
		setIcons((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return nextValues.map((i) => {
				return i.id === icon.id ? { ...i, name: icon.name } : i;
			});
		});
	};

	const handleAddNewIcon = (icon: IIcon) => {
		const newIcons = [...icons, icon];
		setIcons(newIcons);
	};

	const handleSave = () => {
		//TODO: iterar y por cada icono creado (tendran svgName/svgContent) llamar al servicio de añadir icono
		const iconsChanged = icons.filter((icon) => {
			const initialIcon = initialValues.find((i) => i.id === icon.id);
			return initialIcon && icon.name !== initialIcon.name;
		});
		mutate(iconsChanged);
	};

	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} />
			) : (
				<LayoutNoTabs title={t('menu_icons')}>
					{userHasPermissions.WRITE && (
						<>
							<h3 className={styles.blockTitle}>{t('lbl_add_icons')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_add_new_icon')}</p>
							<Button
								slot="start"
								className={styles.editableListBtnAdd}
								onClick={() => {
									setShowAddIconModal(true);
								}}
								disabled
							>
								<Icon className="icon icon-plus iconPlus" />
								<IonLabel>{t('btn_add_icon')}</IonLabel>
							</Button>
						</>
					)}
					{icons
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((icon) => {
							return (
								<IonItem key={`${icon.name}${icon.id}`}>
									{icon.svgName ? (
										<div className={styles.svgIcon} dangerouslySetInnerHTML={{ __html: icon.svgContent }} />
									) : (
										<ServiceIcon url={icon.url} ariaLabel={icon.name}></ServiceIcon>
									)}
									<IonLabel>{icon.name}</IonLabel>
									<IonButton
										className={`btnSelector ${styles.parkingPopoverButton}`}
										disabled={!userHasPermissions}
										fill="clear"
										id={`icon-${icon.id}`}
										slot="end"
									>
										<Icon className="icon icon-ellipsis-vertical md" />
									</IonButton>
									<IonPopover trigger={`icon-${icon.id}`} dismissOnSelect={true} side="bottom" alignment="end">
										<IonContent>
											<IonList className={`${styles.listPadding}`}>
												{popoverOptions.map((item) => {
													return (
														<IonItem
															lines="full"
															key={item.id}
															button={true}
															onClick={() => handleOptions(icon, item.id)}
															detail={false}
														>
															<IonLabel className={styles.body}>{item.text}</IonLabel>
														</IonItem>
													);
												})}
											</IonList>
										</IonContent>
									</IonPopover>
								</IonItem>
							);
						})}

					{userHasPermissions.WRITE && (
						<div className={`${styles.footerButton}`}>
							<Button
								onClick={handleSave}
								className={styles.btnHeader}
								disabled={!isEdited || loadingSave}
								color="primary"
							>
								{t('btn_save_data')}
							</Button>
						</div>
					)}
					<Feedback error={error} success={success} info={info} onDidDismissToast={handleDismissToast} />
					{selectedIcon && (
						<EditIconModal
							showModal={showEditIconModal}
							onClose={() => {
								setShowEditIconModal(false);
								setSelectedIcon(null);
							}}
							selectedIcon={selectedIcon}
							saveChanges={handleUpdateIconName}
							setSuccess={setSuccess}
						/>
					)}
					<AddIconModal
						showModal={showAddIconModal}
						onClose={() => {
							setShowAddIconModal(false);
						}}
						saveChanges={handleAddNewIcon}
						hasWritePermission={userHasPermissions.WRITE}
						setSuccess={setSuccess}
						setError={setError}
					/>
				</LayoutNoTabs>
			)}
		</>
	);
};
