import { IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../../../components/TextInput/TextInput';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { Icon } from '@acciona/ui-ionic-kit';
import { ConfigType, MenuItemTranslation } from '../../../../_api/services/generalServices/types';
import { serviceConfigOptions } from '../helpers';

import styles from './styles.module.scss';
import { FileInput } from '../../../../components/FileInput/FileInput';

type Props = {
	serviceTitle: string;
	serviceConfig: MenuItemTranslation;
	selectedConfig: ConfigType;
	handleChangeServiceConfig: (config: ConfigType) => void;
	handleChangeServiceValue: (key: string, value: any, selectedConfig: ConfigType) => void;
};

const ExternalAccess: React.FC<Props> = ({
	serviceTitle,
	selectedConfig,
	handleChangeServiceConfig,
	handleChangeServiceValue,
	serviceConfig,
}) => {
	const { t } = useTranslation();

	const inputConfig: Record<
		ConfigType,
		{
			type: 'text' | 'toggle' | 'file';
			name: string;
			value: string | boolean;
			label: string;
		}[]
	> = {
		[ConfigType.FILE]: [
			{
				type: 'file',
				name: 'fileName',
				value: serviceConfig?.file?.fileName,
				label: t('file'),
			},
		],
		[ConfigType.EXTERNAL_LINK]: [
			{
				type: 'text',
				name: 'url',
				value: serviceConfig?.externalLink?.url,
				label: t('link'),
			},
			{
				type: 'toggle',
				name: 'inAppBrowser',
				value: serviceConfig?.externalLink?.inAppBrowser,
				label: t('lbl_hide_url_bar'),
			},
		],
		[ConfigType.EXTERNAL_APP_LINK]: [
			{
				type: 'text',
				name: 'deepLinkAndroid',
				value: serviceConfig?.externalAppLink?.deepLinkAndroid,
				label: t('lbl_deep_link_android'),
			},
			{
				type: 'text',
				name: 'deepLinkIOS',
				value: serviceConfig?.externalAppLink?.deepLinkIOS,
				label: t('lbl_deep_link_ios'),
			},
			{
				type: 'text',
				name: 'downloadAppAndroid',
				value: serviceConfig?.externalAppLink?.downloadAppAndroid,
				label: t('lbl_download_link_android'),
			},
			{
				type: 'text',
				name: 'downloadAppIOS',
				value: serviceConfig?.externalAppLink?.downloadAppIOS,
				label: t('lbl_download_link_ios'),
			},
			{
				type: 'text',
				name: 'webUrl',
				value: serviceConfig?.externalAppLink?.webUrl,
				label: t('lbl_app_link_web'),
			},
		],
		[ConfigType.INTERNAL_LINK]: [
			{
				type: 'text',
				name: 'url',
				value: serviceConfig?.internalLink?.url,
				label: t('link'),
			},
		],
	};
	if (!serviceConfig) {
		return;
	}
	return (
		<div>
			<IonItem lines="none">{t('lbl_select_service_link', { name: serviceTitle })}</IonItem>
			<IonRadioGroup
				value={selectedConfig}
				onIonChange={(e) => handleChangeServiceConfig(e.detail.value)}
				className={styles.configSelectorRadio}
			>
				{serviceConfigOptions?.map((item) => {
					return (
						<IonRadio key={item.value} value={item.value} labelPlacement="end">
							{t(item.name)}
						</IonRadio>
					);
				})}
			</IonRadioGroup>
			{false && selectedConfig === ConfigType.FILE && (
				<label className={`${styles.input}`}>
					<span className={`${styles.body} ${styles.color_dark}`}>{t('lbl_select or add_file')}</span>
					<input type="file" accept="image/*" multiple={false} onChange={() => {}} disabled={false} />
					<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
				</label>
			)}
			{inputConfig[selectedConfig]?.map(({ type, name, value, label }) => {
				switch (type) {
					case 'file':
						return (
							<FileInput
								key={name}
								fileName={value as string}
								disabled={false}
								onChange={(e) => {
									handleChangeServiceValue(name, e as any, selectedConfig);
								}}
								accept={'.pdf,.html,.htm'}
							/>
						);
					case 'toggle':
						return (
							<IonItem lines="none" key={name}>
								<IonLabel>{label}</IonLabel>
								<Toggle
									checked={value as boolean}
									onChange={() => {
										handleChangeServiceValue(name, !value, selectedConfig);
									}}
								/>
							</IonItem>
						);
					case 'text':
						return (
							<TextInput
								key={name}
								name={name}
								value={value as string}
								label={label}
								className={styles.linkInput}
								placeholder={t('lbl_write_url')}
								onIonChange={(e) => {
									handleChangeServiceValue(name, e.target.value, selectedConfig);
								}}
								labelPlacement="start"
							/>
						);
					default:
						return <></>;
				}
			})}
		</div>
	);
};

export default ExternalAccess;
