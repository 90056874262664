import { FUNCTIONALITIES } from '../../utils/constants';

export type SplitMenuItem = {
	id: number;
	text: string;
	icon: string;
	link: string;
	functionality: string;
	submenu?: SplitMenuSubMenuItem[];
};

export type SplitMenuSubMenuItem = {
	id: number;
	text: string;
	icon?: string;
	link: string;
	functionality: string;
};

export type SplitMenuExternalLink = {
	id: number;
	text: string;
	icon: string;
	link: string;
};

export const menuTransversal: SplitMenuItem[] = [
	{
		id: 1,
		text: 'menu_services',
		icon: 'icon icon-servicios',
		link: '/transversal/services',
		functionality: FUNCTIONALITIES.superadmin,
	},
	{
		id: 2,
		text: 'menu_roles',
		icon: 'icon icon-occupation',
		link: '/transversal/roles',
		functionality: FUNCTIONALITIES.superadmin,
	},
	{
		id: 3,
		text: 'menu_icons',
		icon: 'icon icon-star',
		link: '/transversal/icons',
		functionality: FUNCTIONALITIES.superadmin,
	},
	{
		id: 0,
		text: '',
		icon: '',
		link: '/blank',
		functionality: FUNCTIONALITIES.public,
	},
];

export const menuCurrentHeadOffice: SplitMenuItem[] = [
	{
		id: 1,
		text: 'menu_desks',
		icon: 'icon icon-seat',
		link: '/workstations',
		functionality: 'DESK',
	},
	{
		id: 2,
		text: 'menu_rooms',
		icon: 'icon icon-room',
		link: '/rooms',
		functionality: 'ROOM',
	},
	{
		id: 3,
		text: 'menu_parking',
		icon: 'icon icon-parking',
		link: '/parking',
		functionality: 'PARKING',
	},
	{
		id: 4,
		text: 'menu_services',
		icon: 'icon icon-documentation',
		link: '/services/settings',
		functionality: 'SERVICES',
		submenu: [
			{
				id: 401,
				text: 'menu_general_settings',
				link: '/services/settings',
				functionality: 'SERVICES',
			},
			{
				id: 402,
				text: 'menu_mobility',
				link: '/services/mobility',
				functionality: 'SERVICES',
			},
			{
				id: 403,
				text: 'menu_restaurants',
				link: '/services/restaurants',
				functionality: 'SERVICES',
			},
		],
	},
	{
		id: 5,
		text: 'menu_communications',
		icon: 'icon icon-communication',
		link: '/communications',
		functionality: 'COMMUNICATIONS',
	},
	{
		id: 6,
		text: 'menu_incidents',
		icon: 'icon icon-incident',
		link: '/incidents',
		functionality: 'INCIDENTS',
	},
	{
		id: 7,
		text: 'menu_terms_and_policies',
		icon: 'icon icon-business',
		link: '/terms',
		functionality: 'TRANSVERSAL',
	},
	{
		id: 8,
		text: 'menu_transversal',
		icon: 'icon icon-globe',
		link: '/transversal-configurations',
		functionality: 'ADMIN',
	},
	{
		id: 9,
		text: 'menu_roles_portal',
		icon: 'icon icon-occupation',
		link: '/roles-portal',
		functionality: 'ADMIN',
	},
	{
		id: 10,
		text: 'menu_permissions_app_management',
		icon: 'icon icon-app-permisions',
		link: '/app-permissions',
		functionality: 'ADMIN',
	},
	{
		id: 0,
		text: '',
		icon: '',
		link: '/blank',
		functionality: FUNCTIONALITIES.public,
	},
];

export const externalLinks: SplitMenuExternalLink[] = [
	{
		id: 1,
		text: 'Habitat',
		icon: 'icon icon-extern',
		link: process.env.REACT_APP_LINK_HABITAT,
	},
	{
		id: 2,
		text: 'TOGO',
		icon: 'icon icon-extern',
		link: process.env.REACT_APP_LINK_TOGO,
	},
];
